import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { useStaticQuery, graphql, Link } from 'gatsby'

import './index.css'

import Vimeo from '../../vimeo'
import IconPlay from '../../../svgs/play.inline.svg'

function HeroHome({ className }) {
  const [videoStatus, setVideoStatus] = useState('hidden')

  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <div className={`md:grid grid-cols-2 ${className}`}>
      <div className="relative row-start-1 col-start-2 mb-6 md:mb-0">
        <div className="home-hero bg-no-repeat p-8 bg-cover">
          {videoStatus === 'hidden' ? (
            <div
              aria-label="play video"
              className="cursor-pointer relative"
              onClick={() => setVideoStatus('visible')}
            >
              <Img fluid={data.hero.childImageSharp.fluid} />
              <div
                className="absolute w-16 text-purple-100"
                style={{
                  top: `50%`,
                  left: `50%`,
                  transform: `translate(-50%, -50%)`,
                }}
              >
                <IconPlay />
              </div>
            </div>
          ) : null}
          {videoStatus === 'visible' ? <Vimeo videoId="410360200" /> : null}
        </div>
      </div>
      <div className="row-start-1 col-start-1 md:mr-8 lg:mr-16 text-center md:text-left">
        <h1 className="text-2xl lg:text-4xl mb-6 lg:mb-10 font-semibold leading-tight">
          If a picture is worth a thousand words, our videos are worth a million
        </h1>
        <p className="mb-10 md:mb-16 lg:text-xl">
          You thought Real Estate Shows was the easiest video tour solution for
          the real estate market before. You’ll be blown away by how easy it is
          now!
        </p>
        <Link
          className="btn btn-teal block text-center md:inline-block"
          to="/pricing"
        >
          Start Your Free Trial
        </Link>
      </div>
    </div>
  )
}

HeroHome.propTypes = {
  className: PropTypes.string,
}

export default HeroHome
