import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

// Internal dependencies
import Layout from '../components/layout'
import SEO from '../components/seo'
import StructuredData from '../components/structured-data'
import HeroHome from '../components/heros/home'
import CreateShowCta from '../components/ctas/create-show'
import { useSiteMetadata } from '../hooks'

// Icons and Images
import IconFast from '../svgs/fast.inline.svg'
import IconEasy from '../svgs/easy.inline.svg'
import IconBeautiful from '../svgs/beautiful.inline.svg'
import purplePattern from '../images/res-pattern-purple.png'
import Filmstrtip from '../images/filmstrip.svg'

function IndexPage({ data }) {
  const siteMetadata = useSiteMetadata()

  return (
    <Layout>
      <SEO />

      <Helmet titleTemplate={`%s`}>
        <link rel="canonical" href={siteMetadata.siteUrl} />
      </Helmet>

      <StructuredData
        pageTitle={siteMetadata.title}
        slug=""
        pageType="webPage"
      />

      <HeroHome className="container mb-10 md:mb-20" />

      <div
        className="bg-purple-600 text-white py-10 lg:py-12 shadow bg-repeat"
        style={{ backgroundImage: `url(${purplePattern})` }}
      >
        <div className="container max-w-3xl text-2xl lg:text-3xl font-semibold text-center">
          Real Estate Shows has helped more than 50,000 real estate agents
        </div>
      </div>

      <div className="container py-20">
        <ul className="grid md:grid-cols-3 gap-20 mb-20 md:mb-40">
          <li className="text-center">
            <IconFast
              className="mb-10 mx-auto h-16 text-purple-700 fill-current"
              style={{ position: 'relative', right: '20px', top: '16px' }}
            />
            <h2 className="font-semibold text-2xl mb-3">Fast</h2>
            <span className="block h-1 rounded w-10 mx-auto mb-3 bg-gray-200"></span>
            <p className="text-lg text-center lg:text-left">
              Shows are automatically created in anywhere from 1 to 2 minutes.
              No one hates waiting as much as we do. We know you’re busy, so we
              made the process quick.
            </p>
          </li>
          <li className="text-center">
            <IconEasy className="mb-6 mx-auto h-20 text-purple-700 fill-current" />
            <h2 className="font-semibold text-2xl mb-3">Easy</h2>
            <span className="block h-1 rounded w-10 mx-auto mb-3 bg-gray-200"></span>
            <p className="text-lg text-center lg:text-left">
              The only thing you have to do is tell us the property address. We
              take care of everything else. Honestly, we’d call this no-touch if
              you didn’t have to hit the submit button.
            </p>
          </li>
          <li className="text-center">
            <IconBeautiful className="mb-6 mx-auto h-20 text-purple-700 fill-current" />
            <h2 className="font-semibold text-2xl mb-3">Beautiful</h2>
            <span className="block h-1 rounded w-10 mx-auto mb-3 bg-gray-200"></span>
            <p className="text-lg text-center lg:text-left">
              There is no product out in the market today that will create both
              video tours and single property web pages that look as good as
              this without any human intervention.
            </p>
          </li>
        </ul>
        <div className="flex flex-col md:flex-row md:items-center">
          <div className="max-w-full w-full mb-6 md:mb-0">
            <img alt="film strip" src={Filmstrtip} />
          </div>
          <div className="text-center md:text-left text-2xl font-semibold md:pl-20">
            An entire video production solution that doesn&apos;t require
            humans. Forget video editing teams, the AI is working for you now.
          </div>
        </div>
      </div>

      <div className="bg-purple-300 py-10 lg:py-20">
        <CreateShowCta
          buttonText="Create Your First Show"
          buttonUrl="https://app.realestateshows.com/listings/preview"
          header="Ready to Get Started?"
        />
      </div>

      <ul className="py-20 md:py-32 container grid gap-10 xl:gap-20 lg:grid-cols-3">
        <li className="relative">
          <Img alt="" fluid={data.video.childImageSharp.fluid} />
          <Link
            aria-label="video tours"
            className="absolute top-0 left-0 w-full h-full"
            to="/video-tours"
          >
            <div className="flex items-center justify-center h-full flex-col text-white">
              <div className="text-3xl font-semibold">Video Tours</div>
              <div className="uppercase tracking-widest">Learn More</div>
            </div>
          </Link>
        </li>
        <li className="relative">
          <Img alt="" fluid={data.property.childImageSharp.fluid} />
          <Link
            aria-label="properties"
            className="absolute top-0 left-0 w-full h-full"
            to="/property-pages"
          >
            <div className="flex items-center justify-center h-full flex-col text-white">
              <div className="text-3xl font-semibold">Property Pages</div>
              <div className="uppercase tracking-widest">Learn More</div>
            </div>
          </Link>
        </li>
        <li className="relative">
          <Img alt="" fluid={data.pricing.childImageSharp.fluid} />
          <Link
            aria-label="pricing "
            className="absolute top-0 left-0 w-full h-full"
            to="/pricing"
          >
            <div className="flex items-center justify-center h-full flex-col text-white">
              <div className="text-3xl font-semibold">Pricing</div>
              <div className="uppercase tracking-widest">Learn More</div>
            </div>
          </Link>
        </li>
      </ul>
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object,
}
export default IndexPage

export const query = graphql`
  query {
    video: file(relativePath: { eq: "video-tours.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    property: file(relativePath: { eq: "property.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pricing: file(relativePath: { eq: "pricing.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
