import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

function CreateShowCta({ header, buttonText, buttonUrl, className }) {
  const { house } = useStaticQuery(graphql`
    query {
      house: file(relativePath: { eq: "interior-image.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 768, quality: 75) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <div className={`max-w-3xl mx-auto relative ${className}`}>
      <div className="relative z-20 py-10 lg:py-16 flex flex-col items-center justify-center">
        <h2 className="text-center text-2xl lg:text-4xl text-white mb-4">
          {header}
        </h2>
        <a
          className="btn btn-teal-outline bg-white font-semibold"
          href={buttonUrl}
        >
          {buttonText}
        </a>
      </div>
      <Img
        className="top-0 left-0 w-full h-full z-10 rounded-lg shadow"
        fluid={house.childImageSharp.fluid}
        style={{ position: `absolute` }}
      />
    </div>
  )
}

CreateShowCta.propTypes = {
  header: PropTypes.string,
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.string,
  className: PropTypes.string,
}

export default CreateShowCta
