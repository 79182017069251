import React from 'react'
import PropTypes from 'prop-types'

function Vimeo({ videoId }) {
  return (
    <>
      <div className="relative" style={{ paddingTop: `56.25%` }}>
        <iframe
          src={`https://player.vimeo.com/video/${videoId}?autoplay=1`}
          style={{
            position: `absolute`,
            top: `0`,
            left: `0`,
            width: `100%`,
            height: `100%`,
          }}
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        ></iframe>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </>
  )
}

Vimeo.propTypes = {
  videoId: PropTypes.string.isRequired,
}

export default Vimeo
