import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

import { useSiteMetadata } from '../hooks'

const StructuredData = props => {
  const { pageType, post, slug, pageTitle, description } = props

  // Get site query from our hook
  const siteMetadata = useSiteMetadata()

  const context = {
    '@context': `https://schema.org`,
  }

  const logo = {
    '@type': 'ImageObject',
    url: `https://realestateshows.com/images/logo.jpg`,
    width: 500,
    height: 67,
  }

  const sameAs = [
    siteMetadata.twitterUrl,
    siteMetadata.facebookUrl,
    siteMetadata.instagramUrl,
  ]

  const organization = {
    ...context,
    '@type': 'Organization',
    name: siteMetadata.title,
    url: siteMetadata.siteUrl,
    logo,
  }

  const website = {
    ...context,
    '@type': `WebSite`,
    '@id': `${siteMetadata.siteUrl}/#website`,
    url: siteMetadata.siteUrl,
    name: siteMetadata.title,
    image: logo,
    sameAs,
    publisher: organization,
  }

  const schema = [{ ...organization }]

  if (pageType === `article`) {
    schema.push({
      ...context,
      '@type': 'Article',
      headline: post.title,
      description: post.seo.description,
      datePublished: post.date,
      dateModified: post.modified,
      image:
        post.featuredImage && post.featuredImage.imageFile
          ? `${siteMetadata.siteUrl}${post.featuredImage.imageFile.publicURL}`
          : null,
      author: {
        '@type': 'Person',
        name: post.author ? post.author.name : siteMetadata.title,
      },
      publisher: { ...organization },
      mainEntityOfPage: `${siteMetadata.siteUrl}/blog/${post.slug}`,
    })
  } else if (pageType === `webPage`) {
    schema.push({
      ...context,
      '@type': `WebPage`,
      '@id': `${siteMetadata.siteUrl}/${slug}#webpage`,
      url: `${siteMetadata.siteUrl}/${slug}`,
      name: pageTitle,
      inLanguage: `en-US`,
      description: description ? description : siteMetadata.description,
      isPartOf: website,
    })
  }

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
}

StructuredData.propTypes = {
  pageType: PropTypes.oneOf([`article`, `webPage`]).isRequired,
  post: PropTypes.shape({
    author: PropTypes.shape({
      name: PropTypes.string,
      avatar: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    date: PropTypes.string,
    modified: PropTypes.string,
    title: PropTypes.string,
    excerpt: PropTypes.string,
    slug: PropTypes.string,
    featuredImage: PropTypes.shape({
      imageFile: PropTypes.object,
    }),
  }),
  slug: PropTypes.string,
  pageTitle: PropTypes.string,
  description: PropTypes.string,
}

export default StructuredData
